<template>
  <div class="md-card export-settings">
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <div class="md-layout-item md-size-100">
          <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="loading" />
          <div class="md-layout-item export-title-container md-small-size-90 md-xsmall-size-90">
            <p class="export-title">Export Faves</p>
          </div>
          <div class="md-layout md-alignment-center-space-around">
            <div class="md-layout-item  md-size-80">
              <md-radio v-model="exportType" value="xml" class="checkbox-name">XML</md-radio>
            </div>
            <div class="md-layout-item  md-size-80">
              <md-radio v-model="exportType" value="excel" class="checkbox-name">CSV</md-radio>
            </div>
            <div class="md-layout-item  md-size-80">
              <md-button type="submit" class="md-raised download-button" @click="downloadExport" :disabled='loading'>
                Download
              </md-button>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-50 right-section right-section-text">
        <h1 class="big-text">
          Export<br>
          your<br>
          <span class="pink-text">Bookmarks</span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import loaderMixin from '@/mixins/loaderMixin';
import { EXPORT_USER_CELLS } from '@/store/modules/user/userActions.type';

export default {
  name: 'Export',
  mixins: [
    loaderMixin,
  ],
  data: () => ({
    exportType: 'xml',
    publicPath: process.env.BASE_URL,
    loading: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    downloadExport() {
      this.loading = true;
      const payload = {
        id: this.user.id,
        exportType: this.exportType,
      };
      this.$store.dispatch(EXPORT_USER_CELLS, payload)
        .then(() => {
          this.$log.info(EXPORT_USER_CELLS, payload);
          this.$toasted.show('Success!', {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.loading = false;
        })
        .catch((error) => {
          this.$log.error(EXPORT_USER_CELLS, error);
          this.$toasted.show('We have encountered an issue. Please try again later.', {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/user-dashboard.scss';
</style>
